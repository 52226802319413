import { Unsubscribe, collection, doc, getDoc, onSnapshot, orderBy, query } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { useEffect, useState } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { Channel, Message } from 'src/types'
import converter from 'src/requests/firebase/utils/dataConverter'
import { useErrorBoundary } from 'react-error-boundary'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'

export function useChannel(phone?: string) {
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()
  const producer = distributor?.producers.find((p) => p.phone === phone)
  const [isLoading, setIsLoading] = useState(true)
  const [channel, setChannel] = useState<Channel>({
    producerName: producer?.name ?? '',
    producerPhone: producer?.phone ?? '',
    createdAt: new Date(),
    chatlogs: [],
    updatedAt: new Date(),
  })

  useEffect(() => {
    let unsub: Unsubscribe | undefined
    ;(async () => {
      if (phone && distributor?.distributorId) {
        if (!producer) throw new Error('404 Producer not found')

        const channelRef = doc(
          db,
          'distributors',
          distributor.distributorId,
          'channels',
          parseAddWhatsAppPhonePrefix(phone),
        ).withConverter(converter<Channel>())

        const q = query(collection(channelRef, 'chatlogs'), orderBy('createdAt', 'asc')).withConverter(
          converter<Message>(),
        )
        const cn = await getDoc(channelRef)

        unsub = onSnapshot(q, (querySnapshot) => {
          try {
            const chatlogs: Message[] = []
            querySnapshot.forEach((doc) => {
              chatlogs.push({
                ...doc.data(),
                id: doc.id,
              })
            })

            if (cn.exists()) {
              setChannel({
                ...cn.data(),
                producerName: producer?.name,
                chatlogs,
              })
            }
          } catch (error) {
            if (error instanceof Error) {
              console.log(error.message)
              showBoundary(error)
            } else {
              console.log(error)
              showBoundary(error)
            }
          } finally {
            setIsLoading(false)
          }
        })
      }
    })()

    return () => {
      if (unsub) {
        unsub()
      }
    }
  }, [phone, distributor?.distributorId])

  return {
    channel,
    isLoadingChannel: isLoading,
  }
}
