import {
  ActionIcon,
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Collapse,
  Flex,
  Group,
  useMantineTheme,
} from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import Navbar from '../Navbar'
import Header from '../Header'
import { useParams, useNavigate } from 'react-router-dom'
import { useChannel } from 'src/hooks/useChannel'
import styles from './Chat.module.css'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { IconChevronDown, IconChevronLeft, IconChevronUp, IconRefresh } from '@tabler/icons-react'
import { getInitials } from 'src/utils/getInitials'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { ChatFooter } from './Footer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'src/types'
import BalloonText from './balloons/Text'
import BalloonImage from './balloons/Image'
import BalloonAudio from './balloons/Audio'
import { getIsFailedMessage } from 'src/utils/getIsFailedMessage'
import sendWhatsappMessage from 'src/requests/sendWhatsappMessage'
import { useDistributor } from 'src/providers/Distributor'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'
import { formatDate } from 'src/utils/formatDate'
import { useOrders } from 'src/providers/Orders'
import { Offers } from './Offers'
import BalloonFile from './balloons/File'

export enum FileExtension {
  'XLSX' = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'PDF' = 'pdf',
  'PNG' = 'png',
  'JPEG' = 'jpeg',
  'JPG' = 'jpg',
}

export type BalloonProps = {
  message?: string | null
  hour: string
  type: MESSAGE_TYPE | string
  mediaUrl?: string | null
  status?: MESSAGE_STATUS | null
  className?: string
  resendFailedMessage?(): void
  isManual?: boolean
  mediaName?: string
  fileExtension?: FileExtension
}

const BalloonTypes: Record<string, React.FC<BalloonProps>> = {
  [MESSAGE_TYPE.AUDIO]: BalloonAudio,
  [MESSAGE_TYPE.IMAGE]: BalloonImage,
  [MESSAGE_TYPE.TEXT_DEFAULT]: BalloonText,
  [MESSAGE_TYPE.TEXT]: BalloonText,
  [MESSAGE_TYPE.APPLICATION]: BalloonFile,
}

function LeftSideBalloon(props: Omit<BalloonProps, 'className' | 'status'>) {
  const Baloon = BalloonTypes[props.type ?? MESSAGE_TYPE.TEXT_DEFAULT]
  return <Baloon className={styles.message_balloon_left} {...props} />
}

function RightSideBalloon({ isManual, ...props }: Omit<BalloonProps, 'className'>) {
  const Baloon = BalloonTypes[props.type ?? MESSAGE_TYPE.TEXT_DEFAULT]
  return <Baloon className={styles.message_balloon_right} data-manual={isManual} {...props} />
}

export default function Chat() {
  const navigate = useNavigate()
  const [navbarIsOpen, { open: openNavbar, close: closeNavbar }] = useDisclosure(false)
  const headerProps = { navbarIsOpen, openNavbar }
  const navProps = { navbarIsOpen, closeNavbar }
  const { phone } = useParams()
  const { channel, isLoadingChannel } = useChannel(phone)
  const { distributor } = useDistributor()
  const firstScrollRef = useRef(true)
  const { orders, isFetching, setShouldRefetch: setShouldRefetchOrders } = useOrders()
  const [footerHeight, setFooterHeight] = useState(70)
  const theme = useMantineTheme()
  const [opened, { toggle }] = useDisclosure(false)

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: firstScrollRef.current ? 'auto' : 'smooth',
    })
    if (firstScrollRef.current && !isLoadingChannel) {
      firstScrollRef.current = false
    }
  }, [channel.chatlogs])

  const modifiedChannels = useMemo(() => {
    return {
      ...channel,
      chatlogs: channel.chatlogs.map((log) => ({
        ...log,
        formattedHour: formatDate(log.createdAt.toDate(), 'dd/MM/yy - HH:mm'),
      })),
    }
  }, [channel])

  const resendFailedMessage = (messageSid: string, isManual?: boolean) => () => {
    const message = modifiedChannels.chatlogs.find((c) => c.id === messageSid)

    if (!phone || !message || !getIsFailedMessage(message.status) || !distributor) return

    sendWhatsappMessage({
      distributorId: distributor.distributorId,
      userNumber: parseAddWhatsAppPhonePrefix(phone),
      resend: true,
      messageSid,
      ...(isManual && { isManual }),
    })
  }

  const producerOffers = useMemo(() => {
    return orders?.filter((order) => !order.sentAt && order.phone === phone && !!order.products.length) ?? []
  }, [orders, phone])

  function refreshAll() {
    setShouldRefetchOrders(true)
  }

  return (
    <AppShell layout="alt" navbar={{ width: 250, breakpoint: 'sm' }} header={{ height: 60 }}>
      <Header {...headerProps} />
      <AppShell.Navbar className={styles.navbar}>
        <Flex w={'100%'} justify={'space-between'} align={'center'}>
          <Button onClick={() => navigate(-1)} variant="transparent" w="fit-content">
            <IconChevronLeft size={isMobile ? 32 : 20} />
          </Button>
          <Burger
            opened={navbarIsOpen}
            onClick={navbarIsOpen ? closeNavbar : openNavbar}
            size="sm"
            mr="md"
            style={{ zIndex: 30 }}
          />
        </Flex>

        <Box className={styles.producer_info}>
          <Avatar color="cyan" radius="999" alt={channel.producerName} className={styles.producer_info_avatar}>
            {getInitials(channel.producerName)}
          </Avatar>
          <Box className={styles.producer_info_text}>
            <Box className={styles.producer_info_text_title} component="span">
              {channel.producerName}
            </Box>
            <Box component="span" className={styles.producer_info_phone}>
              {formatBrazilianNumber(phone ?? '')}
            </Box>
          </Box>
        </Box>
        <Box className={styles.draft_offer_wrap}>
          <Box className={styles.draft_offer_title}>
            <Box component="span">
              <ActionIcon
                onClick={refreshAll}
                loading={isFetching}
                loaderProps={{ type: 'dots' }}
                variant="filled"
                title="Recarregar"
              >
                <IconRefresh size={20} />
              </ActionIcon>
            </Box>
            <Box component="span" ml={2}>
              Ofertas:
            </Box>
          </Box>

          {isMobile ? (
            <Box>
              <Group justify="center">
                <Button onClick={toggle} variant="subtle">
                  <span>Ver Ofertas</span>
                  {opened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
                </Button>
              </Group>
              <Collapse in={opened}>
                <Offers listOffers={producerOffers} refreshOffers={setShouldRefetchOrders} />
              </Collapse>
            </Box>
          ) : (
            <Offers listOffers={producerOffers} refreshOffers={setShouldRefetchOrders} />
          )}
        </Box>
      </AppShell.Navbar>

      <Navbar {...navProps} useDrawer={true} />
      <ErrorBoundary>
        <AppShell.Main bg="#f8f9fa" pt={isMobile ? '10rem' : 'revert-layer'} pb={footerHeight}>
          <Box className={styles.messages_container}>
            {modifiedChannels.chatlogs.map((log) =>
              log.fromOrg ? (
                <RightSideBalloon
                  key={log.id}
                  message={log.content}
                  hour={log.formattedHour}
                  type={log.type}
                  mediaUrl={log.mediaUrl}
                  status={log.status}
                  resendFailedMessage={resendFailedMessage(log.id, log.isManual)}
                  isManual={log.isManual}
                />
              ) : (
                <LeftSideBalloon
                  key={log.id}
                  message={log.content}
                  hour={log.formattedHour}
                  type={log.type}
                  mediaUrl={log.mediaUrl}
                  mediaName={log.mediaName}
                  fileExtension={log.fileExtension}
                />
              ),
            )}
          </Box>
        </AppShell.Main>
        <AppShell.Footer p="md">
          <ChatFooter onChangeFooterHeight={setFooterHeight} />
        </AppShell.Footer>
      </ErrorBoundary>
    </AppShell>
  )
}
